import React from "react";
// Homepage Demo

// Others
import NotFound from "../views/NotFound";
import {HashRouter, Route, Routes} from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";




//Routes
import Demand24 from "./Routing-demand24";

const Routing = () => {
  return (
    <>
      <HashRouter>
        <ScrollTopBehaviour />
        <Routes>
          {/*<Route exact path="/" element={<HomeDark />} />*/}
          {/* Service */}
          {Demand24()}
          {/*page notfound*/}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </>
  );
};

export default Routing;
