export const docMenu = [
  {
    name: "Introduction",
    dropDownItems: [
      {
        name: "Introduction",
        routerPath: "/demand24-documentation/introduction",
      },
      {
        name: "Server requirements",
        routerPath: "/demand24-documentation/recommendations",
      },
    ],
  },
  {
    name: "Backend Api",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/demand24-documentation/server",
      },
      {
        name: "Installation",
        routerPath: "/demand24-documentation/mandatory-setup-backend",
      },
      {
        name: "Payment installation",
        routerPath: "/demand24-documentation/payment-installation",
      },
      {
        name: "Image settings",
        routerPath: "/demand24-documentation/image-settings",
      },
      {
        name: "Troubleshooting",
        routerPath: "/demand24-documentation/troubleshooting-backend",
      },
    ],
  },
  {
    name: "Admin Panel",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/demand24-documentation/admin",
      },
      {
        name: "Installation",
        routerPath: "/demand24-documentation/install-on-server",
      },
      {
        name: "Troubleshooting",
        routerPath: "/demand24-documentation/troubleshooting-admin",
      },
    ],
  },
  {
    name: "Customer Website",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/demand24-documentation/front",
      },
      {
        name: "Installation",
        routerPath: "/demand24-documentation/mandatory-setup-web",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Customer App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/demand24-documentation/mobile-app",
      },
      {
        name: "Installation",
        routerPath: "/demand24-documentation/mandatory-setup-customer",
      },
      {
        name: "Customization",
        routerPath: "/demand24-documentation/customization-customer",
      },
      {
        name: "App build & release",
        routerPath: "/demand24-documentation/customer-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Vendor and Driver App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/demand24-documentation/vendor-app",
      },
      {
        name: "Installation",
        routerPath: "/demand24-documentation/mandatory-setup-vendor",
      },
      {
        name: "Customization",
        routerPath: "/demand24-documentation/customization-vendor",
      },
      {
        name: "App build & release",
        routerPath: "/demand24-documentation/vendor-app-build-release",
      },
    ],
  },
  {
    name: "Pos App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/demand24-documentation/pos-app",
      },
      {
        name: "Installation",
        routerPath: "/demand24-documentation/mandatory-setup-pos",
      },
      {
        name: "Customization",
        routerPath: "/demand24-documentation/customization-pos",
      },
      {
        name: "App build & release",
        routerPath: "/demand24-documentation/pos-app-build-release",
      },
    ],
  },
  {
    name: "Firebase setup",
    routerPath: "/demand24-documentation/firebase",
  },
  {
    name: "Update",
    routerPath: "/demand24-documentation/update",
  },
];
