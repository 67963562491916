import React from "react";
import { Link } from "react-router-dom";

const FirebaseSetup = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Firebase</h1>
      <p className="inner-text">
        We use Firebase Authentication, Cloud Messaging and Firestore in
        Demand24. So, you have to setup Firebase in order to use Demand24
        properly.
      </p>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/OLwNp_e5bxM"
          title="Firebase configuration"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/jCgZZiz1480"
          title="How to run connect firebase to project"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/5HzrGiY9cFo"
          title="Firebase auth configuration"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/3E-kEe5X2bg"
          title="Cloud messaging"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/29ARDVIXvXk"
          title="Firestore"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="center-page-container">
        <Link
          to="/demand24-documentation/deliveryboy-app-build-release"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Build code and setup on server </p>
        </Link>
        <Link
          to="/demand24-documentation/update-admin-panel"
          className="btn  next"
        >
          <p>Next</p>
          <p className="link"> Admin Panel </p>
        </Link>
      </div>
    </div>
  );
};

export default FirebaseSetup;
